<template lang="pug">
  .wrapper
    main
      .register(v-if="!signed")
        .register__wrapper
          a.register__logo(@click="goRoot")
            include ../../assets/images/logo_title.svg
          .register__form
            button.register__form-close(@click="goRoot")

            .register__form-tabs
              router-link(
                :to="{ name: 'SignUp' }"
                v-slot="{ isExactActive }")
                button.register__form-tabs-button(type="button" @click="goSignUp" :class="isExactActive ? 'active' : ''") РЕГИСТРАЦИЯ

              router-link(
                :to="{ name: 'SignIn' }"
                v-slot="{ isExactActive }")
                button.register__form-tabs-button(type="button" @click="goSignIn" :class="isExactActive ? 'active' : ''") ВХОД

            ValidationObserver(ref="form")
              form.register__form-item(
                @submit.prevent="signUp"
                role="form")

                // Email
                label.register__form-label
                  span.register__form-label-title Ваш email
                  span.register__form-label-desc Чтобы мы могли хранить ваши расчеты
                ValidationProvider(
                  :debounce="200"
                  name="email"
                  rules="requiredEmail|email|minEmail:4|maxEmail:60|emailRegistered" )
                  div(slot-scope="{ errors, validated, invalid }")
                    input(
                      :class="validated && invalid ? 'input-error' : ''"
                      name="email"
                      v-model="user.email")
                    span
                      div.styled-errors(
                        role="alert"
                        v-if="validated && invalid")
                        p.errors-text {{ errors[0] }}

                // Password
                label.register__form-label
                  span.register__form-label-title Ваш пароль
                ValidationProvider(
                  name="password"
                  rules="requiredPassword|minPassword:6|maxPassword:20")
                  div(slot-scope="{ errors, validated, invalid }")
                    input(
                      :class="validated && invalid ? 'input-error' : ''"
                      name="password"
                      type="password"
                      v-model="user.password")
                    div.styled-errors(
                      role="alert"
                      v-if="validated && invalid")
                      p.errors-text {{ errors[0] }}

                // Телефон
                label.register__form-label
                  span.register__form-label-title Ваш телефон
                ValidationProvider(
                  :debounce="200"
                  name="phone"
                  rules="requiredPhone|minPhone:8|maxPhone:14")
                  div(slot-scope="{ errors, validated, invalid }")
                    input(
                      :class="validated && invalid ? 'input-error' : ''"
                      name="phone"
                      v-model.number="user.phone")
                    span
                      div.styled-errors(
                        role="alert"
                        v-if="validated && invalid")
                        p.errors-text {{ errors[0] }}

                label.register__form-label
                  span.register__form-label-title Чем вы занимаетесь?
                  span.register__form-label-desc Нам это важно, чтобы делать наш сервис лучше
                ValidationProvider(
                  name="occupation"
                  rules="requiredOccupation")
                  div(slot-scope="{ errors, validated, invalid }")
                    select(
                      :class="validated && invalid ? 'input-error' : ''"
                      name="occupation"
                      v-model="user.occupation")
                      option(v-for="occupation in occupations") {{ occupation }}

                    div.styled-errors(
                      role="alert"
                      v-if="validated && invalid")
                      p.errors-text {{ errors[0] }}

                button.register__form-submit.button.button_smaller.button_green(
                  type="submit" style="cursor: pointer;") Зарегистрироваться

          .register__rules Регистрируясь, вы соглашаетесь с нашей &nbsp;
            a.undecorated-link(href="/policy.pdf", target="_blank") политикой обработки персональных данных

</template>

<script>
import authMixin from 'mixins/auth'
import navigationMixin from 'mixins/navigation'

import { extend } from 'vee-validate'
import { required, min, max } from 'vee-validate/dist/rules'

// import { IMaskComponent } from 'vue-imask'

extend('minEmail', {
  ...min,
  message: 'Email должен быть больше {length} символов'
})
extend('maxEmail', {
  ...max,
  message: 'Email должен быть меньше {length} символов'
})

extend('minPhone', {
  ...min,
  message: 'Телефон должен быть больше {length} символов'
})
extend('maxPhone', {
  ...max,
  message: 'Телефон должен быть меньше {length} символов'
})

extend('minPassword', {
  ...min,
  message: 'Пароль должен быть больше {length} символов'
})
extend('maxPassword', {
  ...max,
  message: 'Пароль должен быть не больше {length} символов'
})
extend('requiredOccupation', {
  ...required,
  message: 'Выберите вариант из списка'
})

export default {
  name: 'SignUp',
  mixins: [authMixin, navigationMixin],
  data () {
    return {
      user: {
        email: null,
        password: null,
        phone: null,
        occupation: null
      },
      isEmailUnique: false,
      occupations: [
        'Строю себе дом',
        'Монтажник систем отопления',
        'Поставщик отопительного оборудования',
        'Архитектор',
        'Проектировщик ОВ',
        'Другое'
      ]
    }
  },
  mounted () {
    extend('emailRegistered', {
      validate: this.isUsernameUnique,
      message: 'Введенный email уже зарегистирован в системе'
    })
  },
  methods: {
    async isUsernameUnique () {
      const response = await this.$api.users.verify_email(this.user.email)
      return response.data.result
    },
    signUp () {
      this.$refs.form.validate().then(success => {
        if (success) this.signUpRequest()
      })
    },
    signUpRequest () {
      const params = {
        email: this.user.email,
        password: this.user.password,
        phone: this.user.phone,
        occupation: this.user.occupation
      }
      this.$reachGoal('REGISTER')
      this.$api.signUp(params)
        .then(({ headers }) => {
          this.$reachGoal('000001')
          const credentials = {
            accessToken: headers['access-token'],
            client: headers.client,
            expiry: headers.expiry,
            tokenType: headers['token-type'],
            uid: headers.uid
          }
          this.cookies.set(process.env.VUE_APP_COOKIE_CRED_NAME, JSON.stringify(credentials),
            {
              domain: process.env.VUE_APP_COOKIE_TLD,
              path: '/',
              sameSite: 'lax'
            })
          window.location = process.env.VUE_APP_DOCS_URL
        })
    }
  }
}
</script>
